import React from 'react';

import Layout from '../components/layout';
import SEO from '../components/seo';
import { useIntl } from 'gatsby-plugin-intl';
import HeroSection from '../components/hero-section';
import TilesList, { TilesListProps } from '../components/tiles-list';
import { generateUniqId } from '../utils/generate-uniq-id';
import SvgKnowledgeSharing from '../images/svgs/magic-lamp.svg';
import SvgExcellenceAtWork from '../images/svgs/magic-lamp.svg';
import SvgStrongRelationShips from '../images/svgs/magic-lamp.svg';
import SvgTeamSpirit from '../images/svgs/magic-lamp.svg';
import GoogleMapsIframe from '../components/google-maps-iframe';

import '../styles/about-page.scss';

const ABOUT_US_ITEMS: TilesListProps['items'] = [
  {
    id: generateUniqId(),
    icon: <SvgKnowledgeSharing />,
    titleLocalizationKey: 'about.QUALITY_TITLE',
    descriptionLocalizationKey: 'about.QUALITY_TILE_DESCRIPTION'
  },
  {
    id: generateUniqId(),
    icon: <SvgExcellenceAtWork />,
    titleLocalizationKey: 'about.WARRANTY_TITLE',
    descriptionLocalizationKey: 'about.WARRANTY_DESCRIPTION'
  },
  {
    id: generateUniqId(),
    icon: <SvgStrongRelationShips />,
    titleLocalizationKey: 'about.SUPPORT_TITLE',
    descriptionLocalizationKey: 'about.SUPPORT_DESCRIPTION'
  },
  {
    id: generateUniqId(),
    icon: <SvgTeamSpirit />,
    titleLocalizationKey: 'about.TEAM_SPIRIT_TILE_TITLE',
    descriptionLocalizationKey: 'about.TEAM_SPIRIT_TILE_DESCRIPTION'
  }
];

const AboutPage: React.FC = () => {
  const intl = useIntl();

  return (
    <Layout>
      <SEO title={intl.formatMessage({ id: 'about.SEO_TITLE' })} />

      <main id="about-page">
        <HeroSection
          titleId="about.ABOUT_HERO_SECTION_SUB_TITLE"
          headingId="about.ABOUT_PAGE_HERO_SECTION_TITLE"
          paragraphId="about.ABOUT_PAGE_HERO_SECTION_PARAGRAPH"
        />

        <section className="section-container section-light">
          <div className="container centered flex-d-column section-headings-container">
            <p className="text-uppercase text-small">
              {intl.formatMessage({
                id: 'about.OUR_VALUES_SECTION_SUB_TITLE'
              })}
            </p>
            <h3 className="section-description">
              {intl.formatMessage({
                id: 'about.OUR_VALUES_SECTION_TITLE'
              })}
            </h3>
            <p>
              {intl.formatMessage({
                id: 'home.HOME_PAGE_ABOUT_SECTION_ABOUT_SUB_TITLE'
              })}
            </p>
          </div>

          <div className="container">
            <TilesList items={ABOUT_US_ITEMS} itemsPerRowOnDesktop={2} />
          </div>
        </section>

        <section className="section-container section-light">
          <div className="container centered flex-d-column section-headings-container">
            <p className="text-uppercase text-small">
              {intl.formatMessage({
                id: 'about.MAP_SECTION_SUB_TITLE'
              })}
            </p>
            <h3 className="section-description">
              {intl.formatMessage({
                id: 'about.MAP_SECTION_TITLE'
              })}
            </h3>
            <p>
              {intl.formatMessage({
                id: 'about.MAP_SECTION_DESCRIPTION'
              })}
            </p>
          </div>

          <div className="container">
            <GoogleMapsIframe />
          </div>
        </section>
      </main>
    </Layout>
  );
};
export default AboutPage;
