import React from 'react';

const GoogleMapsIframe = () => (
  <iframe
    width="600"
    height="450"
    frameBorder="0"
    allowFullScreen
    style={{ border: 0, width: '100%', height: '500px' }}
    src="https://www.google.com/maps/embed/v1/place?q=place_id:ChIJoSs13y9WqEcRzj7qX_f-ySM&key=AIzaSyB27UIkEZlpowCDUniH-DGlFghZREVRzsI"
  ></iframe>
);

export default GoogleMapsIframe;
